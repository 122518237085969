<template>
  <div id="graduate-class-groups" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="graduateClassGroups" :paginated="isPaginated" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ graduateClassGroups.indexOf(props.row) + 1 }}
        </b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.classGroup.name
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
    <!-- Delete -->
    <app-modal
      id="graduating_students"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    >
      <template slot="add">
        <p>This will delete this graduating class group.</p>
        <p>Proceed?</p>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'GraduateClassGroups',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Graduate Class Groups',
      graduateClassGroups: [],
      isPaginated: true,
      id: null,
    }
  },
  watch: {
    graduateClassGroups(data) {
      if (data.length < 20) this.isPaginated = false
    },
  },
  methods: {
    initializeInfo(graduate_class_group) {
      this.$router.push(
        `/school/${this.schoolId}/graduate_class_group_info/${graduate_class_group.classGroup.id}`
      )
    },
    initializeUpdate(graduate_class_group) {
      this.$router.push(
        `/school/${this.schoolId}/graduate_class_group/${graduate_class_group.id}`
      )
    },
    initializeRemove(graduate_class_group) {
      this.id = parseInt(graduate_class_group.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteGraduateClassGroup($id: Int!) {
              deleteGraduateClassGroup(input: { id: $id }) {
                graduateClassGroup {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteGraduateClassGroup.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.graduateClassGroups.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.$route.params.school_id}/graduate_class_group/new`,
      },
      {
        name: 'Graduates',
        route: `/school/${this.schoolId}/graduates`,
      },
    ])
    this.$apollo.addSmartQuery('graduateClassGroups', {
      query: gql`
        query GraduateClassGroupsQuery($schoolId: ID!) {
          graduateClassGroups(schoolId: $schoolId) {
            id
            classGroup {
              id
              name
            }
          }
        }
      `,
      variables: {
        schoolId: this.schoolId,
      },
    })
    this.$apollo.queries.graduateClassGroups.refetch()
  },
}
</script>
